import{
  makeResponsive,
  placeTitle,
  createSpace,
  placeQuestion,
  placeComment,
  createAxes,
  writeHTMLText,
  drawPoint,
  setSize,
  labelIt,
  placeMarker,
  drawCircle,
  placeImage,
  placeShuffle,
  placeTest,
  drawArrow,
  drawAngle,
  drawSegment,
  placeBWhite,
  placeWhite,
  placeBCircles,
  placeCircles,
  placeChecks,
  placeCross,
  placeExclaim,
  hoverMe,
  placeLogo,
  drawMarker,
  toggle,
  toggleTF,
  placeFingers,
  placeAnswers,
  drawTriangle,
  placeRedo,
  placeStartOver,
  print,
  plotFunction,
  setWidth,
  drawLine,
  placePlay,
  placePause,
  writeMediumText,
  drawDash,
  drawIntersection,
  drawPerpP
} from '../Utils.js'
const Boxes = {
  box1: function () {
///////////////////////////////////GLOBAL SETTINGS//////////////////////
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.line.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//////////////////////////////////LOCAL SETTINGS//////////////////////////////////
var graph =createSpace(-8,8,-7,9);
makeResponsive(graph);
graph.options.layer['image']=10;
graph.options.layer['line']=10;
graph.options.layer['point']=12;
//graph.options.layer['polygon']=10;
//createAxes(graph);
placeTitle(graph, 'Parallel Lines', '(Equality of Alternate Interior Angles)');
var time =graph.create('slider',[[0, -17],[4, -17],[-4, -4, 4]]);
time.setAttribute({visible:false, strokeWidth:0});
////////////////////////////////////////////////////////////////////////
var Jt1 = drawPoint(graph, 4, 4);
Jt1.setAttribute({visible:false});
var St1 = drawPoint(graph, 0.0, 4.0);
St1.setAttribute({visible:false});
var St3 = drawPoint(graph, -10.0, 4.0);
St3.setAttribute({visible:false});
var Pt1 = drawPoint(graph, ()=>time.Value(), 4.0);
Pt1.setAttribute({color:'black'});
setSize(graph, Pt1, 3);
labelIt(graph, Pt1, 'A');
Pt1.label.setAttribute({offset:[0, 20]});
//Pt1.setAttribute({snapToGrid:true});
var Pt2 = drawPoint(graph, ()=>-time.Value(), 0.0);
Pt2.setAttribute({color:'black'});
setSize(graph, Pt2, 3);
labelIt(graph, Pt2, 'B');
Pt2.label.setAttribute({offset:[0, -20]});
var Jt2 = drawPoint(graph, 0, 0);
Jt2.setAttribute({visible:false});
var PLn1 = drawLine(graph, St1, Jt1);
PLn1.setAttribute({strokeColor:'blue'});
setWidth(graph, PLn1, '4');
var PLn2 = drawLine(graph, Jt2, Pt2);
PLn2.setAttribute({strokeColor:'blue'});
setWidth(graph, PLn2, '4');
//Pt2.setAttribute({snapToGrid:true});
var Ln =drawLine(graph, Pt1, Pt2);
Ln.setAttribute({strokeColor:'red'});
setWidth(graph, Ln, 4);
var Ptint = drawIntersection(graph, Ln, PLn1);
setSize(graph, Ptint, 0);
//////////////////////////////////////////
var Pt = drawPoint(graph, .0, 0.);
setSize(graph, Pt, 0);
var Pt4 = drawPoint(graph, 10.0, 0.);
setSize(graph, Pt, 0);
///////////////////////////////////////
var perp = drawPerpP(graph, Ln, Pt);
//perp.setAttribute({fillColor:'white', strokeColor:'black'});
setSize(graph, perp, 0);
//labelIt(graph, perp, 'D');
var Lp = drawLine(graph, Pt, perp);
Lp.setAttribute({strokeColor:'red'});
setWidth(graph, Lp, 0);
//drawPerp(graph, Ln, Pt)
//var ints = drawIntersection(graph, Ln, perp);
//ints.setAttribute({size:0});
var alpha =drawAngle(graph, Pt4, Pt2, perp);
alpha.setAttribute({name:()=>(JXG.Math.Geometry.rad(Pt4, Pt2, perp)*180/Math.PI).toFixed(1), fillColor:'blue'});
alpha.label.setAttribute({offset:[10, 10]});
var beta =drawAngle(graph, St3, Ptint, Pt2);
beta.label.setAttribute({offset:[-10, -10]});
beta.setAttribute({name:()=>(JXG.Math.Geometry.rad(St3, Ptint, Pt2)*180/Math.PI).toFixed(1), fillColor:'blue'});
///////////////////////////////////////////////Play section///////////////
var startButton =placePlay(graph, 'left');
hoverMe(graph, startButton, 'Press to Play', 0, -5);
startButton.on('down', function(){time.startAnimation(1, 200, 100)})
//startButton.on('down', function(){time.moveTo([0, -7]); time.moveTo([4, -7],1000)});
var pauseButton =placePause(graph, 'right');
hoverMe(graph, pauseButton, 'Press to Stop', 0, -5);
pauseButton.on('down', function(){time.stopAnimation()})
////////////////////////////////////////////////////////
//n.on('over', function(){time.moveTo([0,-7])});
/*
var funText =writeHTMLText(graph, 2, -1.5, function(){return 'PF ='+(PF.L()).toFixed(2)});
funText.setAttribute({anchorX:'left'});
var iText = writeMediumText(graph, 2, -3.5, function(){return 'PQ ='+(PQ.L()).toFixed(2)});
iText.setAttribute({anchorX:'left'});
*/
/******************END OF FILE ***************************/
}
}
export default Boxes;
